import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';
import { Geofence } from '@ov-suite/models-yard';
import { GeofenceValidatorService } from '../geofence-validator.service';
import { GeofenceService } from '../../../services/geofence/geofence.service';

@Component({
  selector: 'ov-suite-geofence',
  templateUrl: './geofence-add-or-edit.component.html',
  styleUrls: ['./geofence-add-or-edit.component.scss'],
})
export class GeofenceAddOrEditComponent extends AddOrEditHelper<Geofence> {
  constructor(
    public readonly geofenceService: GeofenceService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super(route, router, Geofence, geofenceService, ['geofence']);
  }
}
