import { Component } from '@angular/core';
import { Geofence } from '@ov-suite/models-yard';
import { GeofenceService } from "../../../services/geofence/geofence.service";

@Component({
  selector: 'ov-suite-geofence-list',
  templateUrl: './geofence-list.component.html',
  styleUrls: ['./geofence-list.component.scss'],
})
export class GeofenceListComponent {
  // Class - Required
  formClass = Geofence;

  constructor(public geofenceService: GeofenceService) {}
}
