import { Routes } from '@angular/router';
import { GeofenceAddOrEditComponent } from './add-or-edit/geofence-add-or-edit.component';
import { GeofenceListComponent } from './list/geofence-list.component';

export const GeofenceRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: GeofenceListComponent
      },
      {
        path: 'add',
        component: GeofenceAddOrEditComponent
      },
      {
        path: 'edit',
        component: GeofenceAddOrEditComponent
      }
    ]
  }
];
