import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { GeofenceRoutes } from './geofence.routing';
import { GeofenceListComponent } from './list/geofence-list.component';
import { GeofenceAddOrEditComponent } from './add-or-edit/geofence-add-or-edit.component';
import { GeofenceService } from '../../services/geofence/geofence.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(GeofenceRoutes),
    FormsModule,
    UiModule,
  ],
  declarations: [GeofenceListComponent, GeofenceAddOrEditComponent],
  providers: [GeofenceService],
})
export class GeofenceModule {}
