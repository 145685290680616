<div class="main-content">
    <ov-suite-create-or-edit
      [service]="geofenceService"
      [formClass]="entity"
      [title]="'Geofence'"
      (save)="navigateBack()"
      [dataSources]="dataSources"
    >
    </ov-suite-create-or-edit>
</div>
