import { Routes } from '@angular/router';
import { AdminLayoutComponent, RouteInfo } from '@ov-suite/ui';
import { mobileRoutes } from '@ov-suite/modules-angular';
import { AuthGuard } from '@ov-suite/authguard-angular';

export const angularRoutes: Routes = [
  {
    path: '',
    redirectTo: 'statistics',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('@ov-suite/authguard-angular').then(m => m.UnauthorizedModule),
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      ...mobileRoutes,
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'access-code',
        loadChildren: () => import('./modules/access-code/access-code.module').then(m => m.AccessCodeModule),
      },
      {
        path: 'live-feed',
        loadChildren: () => import('./modules/live-feed/live-feed.module').then(m => m.LiveFeedModule),
      },
      {
        path: 'entered-departed-collections',
        loadChildren: () =>
          import('./modules/entered-departed-collections/entered-departed-collections.module').then(
            m => m.EnteredDepartedCollectionsModule,
          ),
      },
      {
        path: 'historical',
        loadChildren: () => import('./modules/exit-entry-event/exit-entry-event.module').then(m => m.ExitEntryEventModule),
      },
      {
        path: 'entry-reason',
        loadChildren: () => import('./modules/entry-reason/entry-reason.module').then(m => m.EntryReasonModule),
      },
      {
        path: 'exit-reason',
        loadChildren: () => import('./modules/exit-reason/exit-reason.module').then(m => m.ExitReasonModule),
      },
      {
        path: 'stakeholder-location',
        loadChildren: () => import('./modules/stakeholder-location/stakeholder-location.module').then(m => m.StakeholderLocationModule),
      },
      {
        path: 'live-map',
        loadChildren: () => import('./modules/live-map/live-map-dashboard.module').then(m => m.LiveMapDashboardModule),
      },
      {
        path: 'statistics',
        loadChildren: () => import('./modules/statistics/statistics-dashboard.module').then(m => m.StatisticsDashboardModule),
      },
      {
        path: 'geofence',
        loadChildren: () => import('./modules/geofence/geofence.module').then(m => m.GeofenceModule),
      },
      {
        path: 'workflow-processes',
        loadChildren: () => import('./modules/workflow-process/workflow-process.module').then(m => m.WorkflowProcessModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'statistics',
  },
];

export const sidebarRoutes: RouteInfo[] = [
  {
    path: '/',
    title: 'dashboard',
    type: 'sub',
    collapse: 'dashboard',
    icontype: 'ovico-dashboard',
    children: [
      { path: 'live-feed', title: 'Live Feed', ab: 'LF' },
      { path: 'historical', title: 'Historical', ab: 'HT' },
      { path: 'live-map', title: 'Live Map', ab: 'LM' },
      { path: 'statistics', title: 'Statistics', ab: 'ST' },
    ],
  },
  {
    path: '/',
    title: 'General setup',
    type: 'sub',
    collapse: 'general-setup',
    icontype: 'ovico-settings',
    children: [
      { path: 'access-code', title: 'Access Code', ab: 'AC' },
      { path: 'geofence', title: 'Geofence Setup', ab: 'GS' },
    ],
  },
];
