import { OVField, OVTable, OVEntity, OVSearchable } from '@ov-suite/ov-metadata';
import moment from 'moment';

export class ExitEntryEventStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('ExitEntryEvent', 'yardlink')
@OVTable<ExitEntryEvent>([
  {
    key: 'id',
    filterKey: 'id',
    title: 'Entry ID',
    type: 'number',
    disableFiltering: true,
  },
  {
    key: 'processInstanceId',
    title: 'Process Instance ID',
    type: 'string',
    filterKey: 'processInstanceId',
  },
  {
    keys: ['entryTime', 'exitTime'],
    title: 'TAT Status',
    disableFiltering: true,
    disableSorting: true,
    type: 'other',
    hideColumnKey: 'status',
    action: col => {
      if (col && col.entryTime) {
        const start = moment(col.entryTime);
        const diff = moment(!col.exitTime ? new Date() : col.exitTime).diff(start, 'minutes');
        if (diff <= 30) {
          return `<div class="badge badge-success">
          <div class="fa fa-flag mr-2"></div>Valid</div>
        </div>`;
        }
        if (diff > 30 && diff <= 90) {
          return `<div class="badge badge-warning">
        <div class="fa fa-flag mr-2"></div>Violation</div>
      </div>`;
        }
        if (diff > 90 && diff < 180) {
          return `<div class="badge badge-danger">
        <div class="fa fa-flag mr-2"></div>Violation</div>
      </div>`;
        }
        if (diff >= 180) {
          return `<div class="badge badge-dark text-white">
        <div class="fa fa-flag mr-2"></div>Violation</div>
      </div>`;
        }
        return `<div class="badge badge-success">
          <div class="fa fa-flag mr-2"></div>Valid</div>
        </div>`;
      }
      return `<div class="badge badge-secondary text-dark">
        <div class="fa fa-flag mr-2"></div>N/A</div>
      </div>`;
    },
  },
  {
    key: 'identityDisplayName',
    filterKey: 'identityDisplayName',
    title: 'Vehicle',
    type: 'string',
  },
  {
    title: 'Access Code',
    type: 'other',
    action: col => col.accessCode ?? 'None',
    keys: ['accessCode'],
    filterKey: 'accessCode',
    startHidden: false,
  },
  // {
  //   title: 'Arrival Time',
  //   type: 'other'
  //   action: col => moment(col.arrivalTime).format('LLL'),
  //   keys: ['arrivalTime']
  // },
  // {
  //   keys: ['geofence'],
  //   title: 'Current Position',
  //   type: 'other',
  //   hideColumnKey: 'geofence',
  //   action: col => (col?.geofence ? col.geofence : 'Not Available'),
  //   filterKey: 'geofence',
  // },
  {
    title: 'Entry Time',
    type: 'date',
    format: 'DD/MM/YYYY hh:mm:ss A',
    key: 'entryTime',
    filterKey: 'entryTime',
  },
  {
    title: 'Exit Time',
    type: 'date',
    hideColumnKey: 'exitTime',
    format: 'DD/MM/YYYY hh:mm:ss A',
    key: 'exitTime',
    filterKey: 'exitTime',
  },
  {
    keys: ['entryUser'],
    title: 'Entry Guard',
    type: 'other',
    hideColumnKey: 'entryUser',
    action: col => (col?.entryUser ? col.entryUser : 'Not Available'),
    filterKey: 'entryUser',
  },
  {
    keys: ['exitUser'],
    title: 'Exit Guard',
    type: 'other',
    hideColumnKey: 'exitUser',
    action: col => (col?.entryUser ? col.entryUser : 'Not Available'),
    filterKey: 'exitUser',
  },
  {
    title: 'TAT',
    hideColumnKey: 'duration',
    type: 'other',
    keys: ['exitTime', 'entryTime'],
    action: col =>
      moment.duration(moment(!col.exitTime ? new Date() : col.exitTime).diff(col.entryTime, 'minute'), 'minute').humanize(),
  },
  {
    title: 'Actions',
    type: 'buttons',
    buttons: [
      {
        routerLink: pi => ['/workflow-processes/view', `${pi.processInstanceId}`],
        title: 'View',
        classes: 'btn-sm m-1',
      },
    ],
    keys: ['id'],
    disableFiltering: true,
  },
])
export class ExitEntryEvent {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Registration Number',
    placeholder: 'Required',
    required: false,
  })
  registrationNumber?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: ' Access Code',
    placeholder: 'Required',
    required: false,
  })
  accessCode?: string;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Entry Time',
    tooltip: 'Entry TIme',
    placeholder: 'Required',
    required: true,
  })
  entryTime: Date;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Exit Time',
    required: true,
    placeholder: 'Required',
    tooltip: 'Exit Time',
  })
  exitTime: Date;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Entry Guard',
    placeholder: 'Required',
    required: false,
  })
  entryUser?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Exit Guard',
    placeholder: 'Required',
    required: false,
  })
  exitUser?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    required: true,
    tooltip: 'Workflow process instance identifier',
    title: 'Process Instance ID',
  })
  processInstanceId?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    required: true,
    tooltip: 'Identifier Display Name',
    title: 'Identifier Display Name',
  })
  identityDisplayName?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    required: true,
    tooltip: 'Reason',
    title: 'Reason',
  })
  reason?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    required: true,
    tooltip: 'IMEI',
    title: 'IMEI',
  })
  imei?: string;

  @OVField({
    type: () => ExitEntryEventStatus,
    required: true,
    tooltip: 'Status',
    title: 'Status',
  })
  exitEntryEventStatus?: ExitEntryEventStatus;

  // the geofence isn't pulling through neither is it present

  // @OVSearchable()
  // @OVField({
  //   type: 'string',
  //   required: true,
  //   tooltip: 'Site',
  //   title: 'Site',
  // })
  // geofence?: string;
}
