import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Geofence } from '@ov-suite/models-yard';

@Injectable()
export class GeofenceService extends AutoService<Geofence> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('yardlink'), Geofence, 'Geofence', 'Geofences', 'yardlink');
  }
}
